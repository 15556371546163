

















import { Component, Vue } from 'vue-property-decorator';
import { WorkPlatformService } from '@WorkPlatform/api';

@Component({
  name: 'mWorkPlatFormHomeTodo',
  components: {
    listItemTodo: () => import('./homeTodoItem.vue'),
  },
})
export default class mWorkPlatFormHomeTodo extends Vue {
  list: any[] = [];

  /**
   * 获取首页待办
   */
  getTodoList () {
    const params: any = {
      todoType: '1',
      page: 1,
      size: 3,
    };
    return WorkPlatformService.getTodoList(params).then((res: any) => {
      if (res.success) {
        return res;
      } else {
        return {};
      }
    });
  }

  async getList () {
    const data = await this.getTodoList();
    this.list = data.data;
    this.$emit('triggerGetListLength', this.list.length);
  }

  /**
   * 如果流程发生变化，则刷新数据
   */
  reloadPage (evt: any) {
    if (evt.data === 'reload' && evt.origin === window.location.origin) {
      this.getList();
    }
  }

  created () {
    window.addEventListener('message', this.reloadPage, false);
  }

  beforeDestroy () {
    window.removeEventListener('message', this.reloadPage, false);
  }
}
